import React, { useState, useEffect, useContext } from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import AppContext from "./AppContext";
import { CircularProgress } from "@mui/material";
import { UserContext } from "./userContext";
import PaymentModal from "./PaymentModal";
import baseUrl from "./baseUrl";
import axios from "axios";
import LockIcon from "@mui/icons-material/Lock";

// Define your table styles as a JavaScript object
const styles = {
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#1e1e1e",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
    position: "relative",
    height: "80%",
  },
  table: {
    width: "100%", // Full width of the container
    height: "80%",
    borderCollapse: "collapse",
    zIndex: 1,
  },
  cell: {
    padding: "8px",
    textAlign: "left",
    borderBottom: "1px solid #333",
  },
  header: {
    color: "#aaa",
  },
};

const UserInfo = () => {
  const { address, isConnected } = useWeb3ModalAccount();
  const defaultWalletData = [
    ["Inventory Value", "?"],
    ["Wallet Balance", "?"],
    ["Total Asset", "?"],
    ["Wallet Deposit", "?"],
    ["Wallet Withdraw", "?"],
    ["Net Wallet Deposit", "?"],
    ["PNL", "?"],
  ];
  const [walletData, setWalletData] = useState(defaultWalletData);
  const { getPrice, isWhitelisted } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const { selectedAddress, displayName } = useContext(UserContext);

  const controller = new AbortController();
  const signal = controller.signal;

  const fetchStaticUserInfo = async (selectedAddress) => {
    try {
      const url = `${baseUrl}/fetchStaticUserInfo?user=${selectedAddress}`;
      const token = localStorage.getItem("authToken");
      const response = await axios.get(url, {
        headers: { token },
        withCredentials: true,
        signal: signal,
      });
      return response.data;
    } catch (error) {
      console.log("Error fetching category price over time:", error);
    }
  };

  function transformData(jsonObj) {
    const inventoryValue = parseFloat(jsonObj["Inventory Value"]);
    const walletBalance = parseFloat(jsonObj["Wallet Balance"]);
    const walletDeposit = parseFloat(jsonObj["Wallet Deposit"]);
    const walletWithdraw = parseFloat(jsonObj["Wallet Withdraw"]);

    const totalAsset = inventoryValue + walletBalance;
    const netWalletDeposit = walletDeposit - walletWithdraw;
    const pnl = inventoryValue + walletBalance - netWalletDeposit;
    setNoRecordsFound(inventoryValue - 0 < 1e-18);

    return [
      ["Inventory Value", inventoryValue],
      ["Wallet Balance", walletBalance],
      ["Total Asset", totalAsset],
      ["Wallet Deposit", walletDeposit],
      ["Wallet Withdraw", walletWithdraw],
      ["Net Wallet Deposit", netWalletDeposit],
      ["PNL", pnl],
    ];
  }

  useEffect(() => {
    if (!selectedAddress || !isWhitelisted) {
      console.log("User not connected or address not found.");
      controller.abort();
      setWalletData(defaultWalletData);
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      if (!isWhitelisted || !isConnected) {
        setNoRecordsFound(false);
        setLoading(false);
        return;
      }
      try {
        setLoading(true);
        setNoRecordsFound(false);
        const data = await fetchStaticUserInfo(selectedAddress);
        if (data && data["userAssetInEth"]) {
          const transformed = transformData(data["userAssetInEth"]);
          setWalletData(transformed);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching static user info:", error);
      }
    };

    fetchData();
  }, [isConnected, isWhitelisted, selectedAddress]);

  const [noRecordsFound, setNoRecordsFound] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={styles.tableContainer}
    >
      {!loading && !isWhitelisted && isHovered && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            height: "100%",
            position: "absolute",
            backgroundColor: "#1e1e1e",
            marginLeft: -20,
            zIndex: 2,
          }}
        >
          <PaymentModal />
        </div>
      )}
      <h2 style={{ position: "relative", zIndex: 3 }}>
        {displayName} Wallet Info:
        {noRecordsFound && (
          <div style={{ color: "red", fontSize: 16, fontWeight: "normal" }}>
            might not be a Sofamon wallet
          </div>
        )}
      </h2>
      <table style={styles.table}>
        <tbody>
          {loading && (
            <tr>
              <td
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  padding: 100,
                  borderBottom: "none",
                }}
                colSpan="2"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <CircularProgress />
                </div>
              </td>
            </tr>
          )}
          {!loading &&
            walletData.map(([key, value], index) => (
              <tr key={index}>
                <th style={{ ...styles.cell, ...styles.header }}>{key}</th>
                <td style={styles.cell}>
                  {isWhitelisted ? (
                    getPrice(value)
                  ) : (
                    <LockIcon fontSize="small" />
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserInfo;
