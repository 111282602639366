import React, { useState } from "react";
import LockIcon from "@mui/icons-material/Lock";
import PaymentButton from "./PaymentButton";
import { Modal, Box, Button, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Create a dark theme.
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function PaymentModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Styling for the modal using MUI's Box component
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth < 600 ? 300 : 400,
    bgcolor: "#0A1929",
    border: "1x solid #000",
    boxShadow: 10,
    p: 4,
    borderRadius: 10,
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <LockIcon fontSize="large" />
        <Typography variant="body1" marginTop={2}>
          You can unlock this graph by connecting your wallet and subscribing
          for full access
        </Typography>
        <Button
          onClick={showModal}
          variant="contained"
          sx={{
            marginTop: 2,
            color: "white",
            backgroundColor: "rgba(41,97,255, 1)",
          }}
        >
          See more details
        </Button>
      </Box>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mt: 2, color: "white" }}
          >
            <b> One-Time Fee to Gain Full Access</b>
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, color: "white" }}
          >
            Current Price: <b>0.06 ETH</b>
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, color: "white" }}
          >
            Inspired by Sofamon, how we price: <br />
            1st - 19th member: 0.03 ETH <br />
            20th - 39th member: 0.06 ETH <br />
            40th member onwards: 0.09 ETH <br />
          </Typography>
          {window.innerWidth > 1024 ? (
            <>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, color: "white" }}
              >
                *You may connect to any wallet for payment. Afterwards you can
                enter your Sofamon Wallet above to see personalized analytics.
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <PaymentButton handleNoWallet={closeModal} />
                <Button
                  onClick={closeModal}
                  variant="outlined"
                  sx={{ marginTop: 2, color: "white" }}
                >
                  No Thanks
                </Button>
              </div>
            </>
          ) : (
            <>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, color: "white" }}
              >
                *We only support payment on our DESKTOP version, please head to
                https://www.sofamonpro.com on your desktop browser to pay and
                become whitelisted with your wallet of choice!
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <Button
                  onClick={closeModal}
                  variant="contained"
                  sx={{
                    marginTop: 2,
                    backgroundColor: "rgba(41,97,255, 1)",
                    color: "white",
                  }}
                >
                  I Understand!
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </ThemeProvider>
  );
}

export default PaymentModal;
