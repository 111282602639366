import React, { useState, useEffect } from "react";
import UserInfo from "./UserInfo";
import SofamonInfo from "./SofamonInfo";
import Nav from "./Nav";
import PriceChart from "./priceChart";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import AssetChart from "./assetChart";
import AppContext from "./AppContext";
import { UserProvider } from "./userContext";
import axios from "axios";
import baseUrl from "./baseUrl";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import * as amplitude from "@amplitude/analytics-browser";
import { BrowserProvider, Contract, parseEther } from "ethers";
import WhitelistABI from "./artifacts/contracts/Whitelist.sol/Whitelist.json";
import { WHITELIST_CONTRACT_ADDRESS } from "./constants";
amplitude.init("456751549960bd00fc01601c0cd9ef0a");

const styles = {
  app: {
    backgroundColor: "#121212",
    color: "white",
    minHeight: "100vh",
    padding: "20px",
  },
  box: {
    display: "flex", // Make sure the content uses flex layout to control height
    flexDirection: "column", // Stack children vertically
    backgroundColor: "#1e1e1e",
    padding: "20px",
    borderRadius: "10px",
    height: "90%", // Make the box take up full parent height
  },
};

const UserTable = [
  ["Nominal Asset", "0"],
  ["Real Asset", "0"],
  ["How much I put in", "0"],
  ["How much I withdrew", "0"],
  ["PNL", "0"],
  ["Transaction Fee paid", "0"],
];

const App = () => {
  const [currency, setCurrency] = useState("ETH");
  const [getPrice, setGetPrice] = useState(() => (price) => {
    return price;
  });
  const [isWhitelisted, setIsWhitelisted] = useState(false);

  const [ethPrice, setEthPrice] = useState(1);
  const { address, isConnected, chainId } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const fetchIsWhitelisted = async () => {
    if (!isConnected) {
      await axios.post(`${baseUrl}/logout`, {
        wallet: address,
      });
      localStorage.removeItem("authToken");
      setIsWhitelisted(false);
      return;
    }
    try {
      let response = await axios.get(
        `${baseUrl}/isWhitelisted?wallet=${address}`
      );
      let { isWhitelisted } = response.data;
      if (!isWhitelisted) {
        const ethersProvider = new BrowserProvider(walletProvider);
        const signer = await ethersProvider.getSigner();
        const WhitelistContract = new Contract(
          WHITELIST_CONTRACT_ADDRESS[chainId],
          WhitelistABI.abi,
          signer
        );
        isWhitelisted = await WhitelistContract.isWhitelisted(address);
        if (isWhitelisted) {
          try {
            await axios.post(`${baseUrl}/addWallet`, {
              wallet: address,
            });
          } catch (error) {
            console.log(error);
          }
        }
      }
      if (isWhitelisted) {
        response = await axios.post(`${baseUrl}/login`, {
          wallet: address,
        });
        const token = response.data;
        localStorage.setItem("authToken", token);
        amplitude.track("Login");
      }
      setIsWhitelisted(isWhitelisted);
    } catch (error) {
      let errorMessage = error.reason || error.message;
      if (errorMessage.includes("revert")) {
        const revertReasonMatch = errorMessage.match(/revert (.*)/);
        if (revertReasonMatch && revertReasonMatch[1]) {
          errorMessage = revertReasonMatch[1];
        }
      }
      alert(errorMessage);
      setIsWhitelisted(false);
    }
  };

  useEffect(() => {
    fetchIsWhitelisted();
  }, [isConnected, address]);

  useEffect(() => {
    const fetchCurrentEthPrice = async () => {
      const response = await axios.get(`${baseUrl}/getCurrentEthPrice`);
      const priceFunction =
        currency === "USD"
          ? (price) => {
              return `$${(
                parseFloat(price) * parseFloat(response.data.eth_price)
              ).toFixed(2)}`;
            }
          : (price) => {
              return parseFloat(price).toFixed(4);
            };
      setEthPrice(response.data.eth_price);
      setGetPrice(() => priceFunction);
    };

    fetchCurrentEthPrice();
  }, [currency]);

  return (
    <AppContext.Provider
      value={{
        getPrice,
        currency,
        setCurrency,
        ethPrice,
        isWhitelisted,
        setIsWhitelisted,
      }}
    >
      <UserProvider>
        <Box sx={{ flexGrow: 1, ...styles.app }}>
          <Nav />
          <Grid style={{ marginTop: "64px" }} container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box sx={styles.box}>
                <UserInfo data={UserTable} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={styles.box}>
                <SofamonInfo />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={styles.box}>
                <AssetChart />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={styles.box}>
                <PriceChart />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <footer style={{ color: "white", textAlign: "center" }}>
          <p>For questions: +1 9495655311 on Telegram</p>
        </footer>
      </UserProvider>
    </AppContext.Provider>
  );
};
export default App;
