import { createContext } from "react";

const AppContext = createContext({
  ethPrice: 0,
  currency: "USD",
  setCurrency: () => {},
  getPrice: () => {},
  isWhitelisted: false,
  setIsWhitelisted: () => {},
});

export default AppContext;
