import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const options = [
  "NORA Bomber Jacket",
  "NORA Cat",
  "NORA Glasses",
  "NORA Sweater",
  "NORA Beanie",
  "Node Cap",
  "Node Sunglasses",
  "Node Hoodie",
  "Node Shirt",
  "Node Long Sleeve",
  "Eigen T-Shirt",
  "Eigen Long Sleeve",
  "Eigen Hoodie",
  "Eigen Camo Hoodie",
  "Infinity Stone",
  "Based Joint",
  "Based Overalls",
  "Based Jacket",
  "Based Cap",
  "Based Long Sleeve",
  "FT Cap",
  "FT Kimono",
  "FT Puffer Jacket",
  "Rare",
  "Uncommon",
  "Common",
  "NORA",
  "Node",
  "Eigen",
  "Based",
  "FT",
  // "Hat",
  // "Top",
  // "Accessories",
  "All",
];

export default function SelectLabels({ category, setCategory }) {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  React.useEffect(() => {
    setCategory("All");
  }, []);

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-label">Category</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={category}
          label="Category"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}
