import React, { useState, useContext, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { UserContext } from "./userContext";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";

const SearchBar = ({}) => {
  const { addressesAndProfiles, setSelectedAddress, selectedAddress } =
    useContext(UserContext);

  const { address, isConnected } = useWeb3ModalAccount();

  const [ options, setOptions ] = useState([]);

  useEffect(() => {
    if (!selectedAddress) {
      setSelectedAddress(address);
    }
  }, [selectedAddress]);

  // useEffect(() => {
  //   if (addressesAndProfiles) {
  //     setOptions(convertAddressesToProfilesToOptions(addressesAndProfiles));
  //   }
  // }, [addressesAndProfiles]);

  const formatAddressAndProfileOption = (address) => {
    let fullOption = "";

    if (!addressesAndProfiles[address] || !addressesAndProfiles[address]["username"]) {
      fullOption = address;
    } else {
      fullOption = `${addressesAndProfiles[address]["username"]} (${address})`;
    }

    return `${fullOption.substring(0, 14)}...${fullOption.substring(fullOption.length - 12)}`
  };

  // const convertAddressesToProfilesToOptions  = (addressesAndProfiles) => {
  //   return Object.keys(addressesAndProfiles).map((address) => {
  //     if (!addressesAndProfiles[address] || !addressesAndProfiles[address]["username"]) {
  //       return address;
  //     } else {
  //       return `${addressesAndProfiles[address]["username"]} (${address})`;
  //     }
  //   });
  // }

  return (
    <Autocomplete
      freeSolo
      options={addressesAndProfiles ? Object.keys(addressesAndProfiles) : []}
      sx={{
        width: "300px",
        marginTop: "5px",
        marginBottom: "5px",
        "& .MuiOutlinedInput-root": {
          borderRadius: "5px",
          borderColor: "lightgray",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "lightgray",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "lightgray",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "lightgray",
        },
        "& .MuiInputLabel-outlined": {
          color: "lightgray",
        },
        "&:hover .MuiInputLabel-outlined": {
          color: "lightgray",
        },
        "&.Mui-focused .MuiInputLabel-outlined": {
          color: "lightgray",
        },
      }}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{
            "& > hr": {
              mt: "5px",
              mb: "5px",
              borderColor: "gray",
            },
            color: "white",
          }}
          {...props}
        >
          {formatAddressAndProfileOption(option)}
        </Box>
      )}
      filterOptions={(options, params) => {
        const filtered = options.filter((option) => {
          if (!addressesAndProfiles || !addressesAndProfiles[option] || !addressesAndProfiles[option]["username"]) {
            return option.toLowerCase().includes(params.inputValue.toLowerCase())
          } else {
            const addressAndUsername = `${addressesAndProfiles[option].username} ${option}`;

            return (
              addressAndUsername.toLowerCase().includes(params.inputValue.toLowerCase())
            );
          }

        });
      
        return filtered;
      }}
      PaperComponent={({ children }) => (
        <Paper
          style={{
            backgroundColor: "rgb(43,46,56)",
            borderRadius: "10px",
          }}
        >
          {children}
        </Paper>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            borderColor: "lightgray",
            "& .MuiInputBase-input": {
              borderColor: "lightgray",
              color: "white",
            },
            "&:hover .MuiInputBase-input, &:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "lightgray", // This line ensures the border color stays lightgray on hover
              },
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: "lightgray",
            },
            "&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "lightgray",
              },
            "& .MuiInputLabel-outlined": {
              color: "lightgray",
            },
            "&:hover .MuiInputLabel-outlined": {
              color: "lightgray",
            },
            "&.Mui-focused .MuiInputLabel-outlined": {
              color: "lightgray",
            },
          }}
          label="Search Sofamon"
        />
      )}
      onChange={(event, newValue) => setSelectedAddress(newValue)}
    />
  );
};

export default SearchBar;
