import React, { useState, useEffect, useRef } from "react";
import { createChart } from "lightweight-charts";
import CustomDropdown from "./categoryChange";
import { CircularProgress } from "@mui/material";
import baseUrl from "./baseUrl";

import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import PaymentModal from "./PaymentModal";
import AppContext from "./AppContext";
import { UserContext } from "./userContext";
import axios from "axios";
import "./priceChart.css";

const AssetChart = () => {
  const intervals = ["1h", "24h", "7d", "30d"];
  const [activeInterval, setActiveInterval] = useState(intervals[1]);
  const [category, setCategory] = useState("All");
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const [seriesesData, setSeriesesData] = useState({});
  const [loading, setLoading] = useState(false);
  const { isConnected } = useWeb3ModalAccount();
  const { getPrice, currency, isWhitelisted } = React.useContext(AppContext);
  const { displayName, selectedAddress } = React.useContext(UserContext);
  const [noRecordsFound, setNoRecordsFound] = useState(false);
  const [inititalIntervalPrice, setInitialIntervalPrice] = useState(1);
  const [legendInfo, setLegendInfo] = useState({
    priceFormatted: "0",
    percentChange: "+0%",
    isPositive: false,
  });
  const [currentPrice, setCurrentPrice] = useState(0);
  const areaSeriesRef = useRef(null);

  const transformPriceData = (priceData) => {
    const timezoneOffset = new Date().getTimezoneOffset();
    priceData = Object.keys(priceData).map((key) => {
      return {
        time: key - timezoneOffset * 60,
        value: parseFloat(priceData[key]),
      };
    });

    const sortedPriceData = priceData.sort((a, b) => a.time - b.time);

    return sortedPriceData;
  };

  const controller = new AbortController();
  const signal = controller.signal;

  const fetchUserAssetOverTime = async (
    category,
    timeFrame,
    selectedAddress
  ) => {
    try {
      const url = `${baseUrl}/fetchUserAssetOverTime?time=${timeFrame}&user=${selectedAddress}`;
      const token = localStorage.getItem("authToken");
      const response = await axios.get(url, {
        headers: { token },
        withCredentials: true,
        signal: signal,
      });
      return response.data;
    } catch (error) {
      console.log("Error fetching category price over time:", error);
    }
  };

  const updateSeriesesData = async (
    seriesesData,
    category,
    activeInterval,
    selectedAddress
  ) => {
    setLoading(true);
    setNoRecordsFound(false);
    const data = await fetchUserAssetOverTime(
      category,
      activeInterval,
      selectedAddress
    );
    seriesesData[category][activeInterval] = transformPriceData(data);
    setSeriesesData(seriesesData);
    setLoading(false);
  };

  const [currentAddress, setCurrentAddress] = useState(selectedAddress);

  useEffect(() => {
    if (!activeInterval || !category || !isWhitelisted || !selectedAddress) {
      setSeriesesData({});
      setLoading(false);
      controller.abort();
      return;
    }
    setNoRecordsFound(false);
    let currenSeriesesData = seriesesData;
    if (currentAddress !== selectedAddress) {
      currenSeriesesData = {};
      setCurrentAddress(selectedAddress);
    }

    if (!currenSeriesesData[category]) {
      currenSeriesesData[category] = {};
      updateSeriesesData(
        currenSeriesesData,
        category,
        activeInterval,
        selectedAddress
      );
    } else if (!currenSeriesesData[category][activeInterval]) {
      updateSeriesesData(
        currenSeriesesData,
        category,
        activeInterval,
        selectedAddress
      );
    }
  }, [activeInterval, category, isConnected, isWhitelisted, selectedAddress]);

  useEffect(() => {
    if (chartContainerRef.current) {
      const chart = createChart(chartContainerRef.current, {
        width: chartContainerRef.current.offsetWidth,
        height: chartContainerRef.current.offsetHeight,
        layout: {
          background: { type: "solid", color: "rgb(30,30,30)" },
          textColor: "#d1d4dc",
        },
        grid: {
          vertLines: { color: "rgba(42, 46, 57, 0.5)" },
          horzLines: { color: "rgba(42, 46, 57, 0.5)" },
        },
        rightPriceScale: { borderVisible: false },
        timeScale: {
          borderVisible: false,
          timeVisible: true,
          minBarSpacing: 0.001,
        },
        crosshair: { horzLine: { visible: true } },
        autoSize: true,
      });
      chart.applyOptions({
        handleScroll: {
          mouseWheel: false,
          pressedMouseMove: false,
        },
        handleScale: {
          mouseWheel: false,
          pinch: false,
          axisDoubleClickReset: false,
        },
        watermark: {
          visible: true,
          fontSize: 24,
          horzAlign: "center",
          vertAlign: "center",
          color: "rgba(128,128,128)",
          text: "SofamonPro.com",
        },
      });
      chart.addLineSeries({
        priceFormat: {
          type: "custom",
          formatter: (price) =>
            currency === "USD" ? price.toFixed(2) : price.toFixed(4),
        },
      });

      chartRef.current = chart;
      chartRef.current.subscribeCrosshairMove((param) => {
        if (param.time && areaSeriesRef.current) {
          const seriesData = param.seriesData.get(areaSeriesRef.current);
          if (seriesData) {
            const newCurrentPrice = seriesData.value;
            setCurrentPrice(newCurrentPrice); // Update currentPrice state
          }
        }
      });
    }

    return () => chartRef.current?.remove();
  }, [currency]);

  // useEffect to update legendText when percentChange, currentPrice, or currency changes
  useEffect(() => {
    if (inititalIntervalPrice && currentPrice) {
      const newPercentChange = (
        ((currentPrice - inititalIntervalPrice) / inititalIntervalPrice) *
        100
      ).toFixed(2);
      const isPositive = newPercentChange >= 0;
      const sign = isPositive ? "+" : "";
      const priceFormatted =
        currency === "USD"
          ? `$${currentPrice.toFixed(2)}`
          : `${currentPrice.toFixed(4)} ${currency}`;

      setLegendInfo({
        priceFormatted,
        percentChange: `${sign}${newPercentChange}%`,
        isPositive,
      });
    }
  }, [inititalIntervalPrice, currentPrice, currency]);

  useEffect(() => {
    if (
      loading ||
      !chartRef.current ||
      !seriesesData[category] ||
      !isWhitelisted
    ) {
      return;
    }
    const seriesData = seriesesData[category][activeInterval];
    if (!seriesData) {
      return;
    }
    if (seriesData.length === 0) {
      setNoRecordsFound(true);
    } else {
      setNoRecordsFound(false);
    }

    chartRef.current.timeScale().setVisibleLogicalRange({
      from: 0,
      to: seriesData.length,
    });

    areaSeriesRef.current = chartRef.current.addAreaSeries({
      topColor: "rgba(41,97,255, 0.56)",
      bottomColor: "rgba(41,97,255, 0.04)",
      lineColor: "rgba(41,97,255, 1)",
      lineWidth: 2,
    });
    const displayedSeries = [];

    for (let i = 0; i < seriesData.length; i++) {
      const originalData = seriesData[i];
      let newValue;
      if (currency === "USD") {
        newValue = parseFloat(getPrice(originalData.value).substring(1));
      } else {
        newValue = parseFloat(getPrice(originalData.value));
      }
      displayedSeries.push({
        time: originalData.time,
        value: newValue,
      });
    }

    if (seriesData.length) {
      if (currency === "USD") {
        setInitialIntervalPrice(
          parseFloat(getPrice(seriesData[0]["value"]).substring(1))
        );
        setCurrentPrice(
          parseFloat(
            getPrice(seriesData[seriesData.length - 1]["value"]).substring(1)
          )
        );
      } else {
        setInitialIntervalPrice(parseFloat(getPrice(seriesData[0]["value"])));
        setCurrentPrice(
          parseFloat(getPrice(seriesData[seriesData.length - 1]["value"]))
        );
      }
    }

    areaSeriesRef.current.setData(displayedSeries);
    return () => {
      if (chartRef.current && areaSeriesRef.current) {
        try {
          chartRef.current.removeSeries(areaSeriesRef.current);
        } catch (error) {
          console.error("Error removing series:", error);
        }
      }
    };
  }, [
    loading,
    chartRef.current,
    seriesesData,
    activeInterval,
    category,
    getPrice,
    selectedAddress,
    isWhitelisted,
  ]);

  const handleIntervalChange = (interval) => setActiveInterval(interval);

  return (
    <div>
      <div className="chart-title">
        {displayName} Sofamon
        Inventory ({currency})
      </div>
      <div className="legend-text">
        {legendInfo.priceFormatted}
        {` `}
        <span style={{ color: legendInfo.isPositive ? "#26AD75" : "#F0616D" }}>
          ({legendInfo.percentChange})
        </span>
      </div>
      <div className="controls-container">
        <div className="switcher">
          {intervals.map((item) => (
            <button
              key={item}
              className={`switcher-item ${
                item === activeInterval ? "switcher-active-item" : ""
              }`}
              onClick={() => handleIntervalChange(item)}
            >
              {item === "30d" ? "All" : item}
            </button>
          ))}
        </div>
        <div style={{ visibility: "hidden" }}>
          <CustomDropdown category={category} setCategory={setCategory} />
        </div>
      </div>
      <div>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginBottom: -300,
              height: "300px",
              minHeight: "300px",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {!loading && !isWhitelisted && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginBottom: -300,
              height: "300px",
              minHeight: "300px",
            }}
          >
            <PaymentModal />
          </div>
        )}
        {noRecordsFound && (
          <div
            style={{
              color: "red",
              fontSize: 16,
              fontWeight: "normal",
            }}
          >
            might not be a Sofamon wallet
          </div>
        )}
        <div
          style={{
            width: "100%",
            height: "300px",
            minHeight: "300px",
            visibility: !loading && isWhitelisted ? "visible" : "hidden",
          }}
          ref={chartContainerRef}
        ></div>
      </div>
    </div>
  );
};

export default AssetChart;
