import React, { useState, useEffect, useRef, useContext } from "react";
import { createChart } from "lightweight-charts";
import "./priceChart.css";
import CustomDropdown from "./categoryChange";
import { CircularProgress } from "@mui/material";
import AppContext from "./AppContext";
import baseUrl from "./baseUrl";
import PaymentModal from "./PaymentModal";
import axios from "axios";

const PriceChart = () => {
  const { getPrice, currency, isWhitelisted } = useContext(AppContext);
  const intervals = ["1h", "24h", "7d", "30d"];
  const [activeInterval, setActiveInterval] = useState(intervals[1]);
  const [category, setCategory] = useState("All");
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const [seriesesData, setSeriesesData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDefaultGraph, setIsDefaultGraph] = useState(true);

  const [inititalIntervalPrice, setInitialIntervalPrice] = useState(1);
  // const [legendText, setLegendText] = useState('Hi Sofamon');
  const [legendInfo, setLegendInfo] = useState({
    priceFormatted: "0",
    percentChange: "+0%",
    isPositive: false,
  });
  const [currentPrice, setCurrentPrice] = useState(0);
  const areaSeriesRef = useRef(null);

  const transformPriceData = (priceData) => {
    const timezoneOffset = new Date().getTimezoneOffset();
    priceData = Object.keys(priceData).map((key) => {
      return {
        time: key - timezoneOffset * 60,
        value: parseFloat(priceData[key].price),
      };
    });

    const sortedPriceData = priceData.sort((a, b) => a.time - b.time);

    // Prepend 0.01 to the earliest timestamp for all data
    // TODO: when 30 days hit the earliest timestamp make sure to update this
    if (activeInterval === "30d" || activeInterval === "all") {
      for (let i = 0; i < Math.floor(sortedPriceData.length / 100); i++) {
        sortedPriceData.unshift({
          time: sortedPriceData[0].time - (i + 1) * timezoneOffset * 60,
          value: 0.01,
        });
      }
    }
    return sortedPriceData;
  };

  const controller = new AbortController();
  const signal = controller.signal;

  const fetchCategoryPriceOverTime = async (category, timeFrame) => {
    const url = `${baseUrl}/fetchCategoryPriceOvertime?category=${category}&time=${timeFrame}`;
    const token = localStorage.getItem("authToken");
    const response = await axios.get(url, {
      headers: { token },
      withCredentials: true,
      signal: signal,
    });
    return response.data;
  };

  const fetchOverallPriceOver24h = async () => {
    const url = `${baseUrl}/fetchOverallPriceOver24h`;
    const response = await axios.get(url, {
      signal: signal,
    });
    return response.data;
  };

  const updateSeriesesData = async (category, activeInterval) => {
    try {
      setLoading(true);
      let data;
      if (category === "All" && activeInterval === "24h") {
        data = await fetchOverallPriceOver24h();
      } else {
        data = await fetchCategoryPriceOverTime(category, activeInterval);
      }
      seriesesData[category][activeInterval] = transformPriceData(data);
      setSeriesesData(seriesesData);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching category price over time:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!activeInterval || !category || (!isWhitelisted && !isDefaultGraph)) {
      controller.abort();
      setLoading(false);
      return;
    }
    if (!seriesesData[category]) {
      seriesesData[category] = {};
      updateSeriesesData(category, activeInterval);
    } else if (!seriesesData[category][activeInterval]) {
      updateSeriesesData(category, activeInterval);
    }
  }, [activeInterval, category, isWhitelisted]);

  useEffect(() => {
    setIsDefaultGraph(activeInterval === "24h" && category === "All");
  }, [activeInterval, category]);

  useEffect(() => {
    if (chartContainerRef.current) {
      const chart = createChart(chartContainerRef.current, {
        width: chartContainerRef.current.offsetWidth,
        height: chartContainerRef.current.offsetHeight,
        layout: {
          background: { type: "solid", color: "rgb(30,30,30)" },
          textColor: "#d1d4dc",
        },
        grid: {
          vertLines: { color: "rgba(42, 46, 57, 0.5)" },
          horzLines: { color: "rgba(42, 46, 57, 0.5)" },
        },
        rightPriceScale: { borderVisible: false },
        timeScale: {
          borderVisible: false,
          timeVisible: true,
          minBarSpacing: 0.001,
        },
        crosshair: { horzLine: { visible: true } },
      });
      chart.applyOptions({
        handleScroll: false,
        handleScale: false,
        autoSize: true,
        watermark: {
          visible: true,
          fontSize: 24,
          horzAlign: "center",
          vertAlign: "center",
          color: "rgba(128,128,128)",
          text: "SofamonPro.com",
        },
      });
      chart.addLineSeries({
        priceFormat: {
          type: "custom",
          formatter: (price) =>
            currency === "USD" ? price.toFixed(2) : price.toFixed(4),
        },
      });

      chartRef.current = chart;
      chartRef.current.subscribeCrosshairMove((param) => {
        if (param.time && areaSeriesRef.current) {
          const seriesData = param.seriesData.get(areaSeriesRef.current);
          if (seriesData) {
            const newCurrentPrice = seriesData.value;
            setCurrentPrice(newCurrentPrice); // Update currentPrice state
          }
        }
      });
    }

    return () => chartRef.current?.remove();
  }, [currency, isWhitelisted]);

  // useEffect to update legendText when percentChange, currentPrice, or currency changes
  useEffect(() => {
    if (inititalIntervalPrice && currentPrice) {
      const newPercentChange = (
        ((currentPrice - inititalIntervalPrice) / inititalIntervalPrice) *
        100
      ).toFixed(2);
      const isPositive = newPercentChange >= 0;
      const sign = isPositive ? "+" : "";
      const priceFormatted =
        currency === "USD"
          ? `$${currentPrice.toFixed(2)}`
          : `${currentPrice.toFixed(4)} ${currency}`;

      setLegendInfo({
        priceFormatted,
        percentChange: `${sign}${newPercentChange}%`,
        isPositive,
      });

      console.log({
        priceFormatted,
        percentChange: `${sign}${newPercentChange}%`,
        isPositive,
      });
    }
  }, [inititalIntervalPrice, currentPrice, currency]);

  useEffect(() => {
    if (
      loading ||
      !chartRef.current ||
      !seriesesData[category] ||
      (!isWhitelisted && !isDefaultGraph)
    )
      return;
    const seriesData = seriesesData[category][activeInterval];
    if (!seriesData) {
      return;
    }

    chartRef.current.timeScale().setVisibleLogicalRange({
      from: 0,
      to: seriesData.length,
    });

    areaSeriesRef.current = chartRef.current.addAreaSeries({
      topColor: "rgba(41,97,255, 0.56)",
      bottomColor: "rgba(41,97,255, 0.04)",
      lineColor: "rgba(41,97,255, 1)",
      lineWidth: 2,
    });

    const displayedSeries = [];

    for (let i = 0; i < seriesData.length; i++) {
      const originalData = seriesData[i];
      let newValue;
      if (currency === "USD") {
        newValue = parseFloat(getPrice(originalData.value).substring(1));
      } else {
        newValue = parseFloat(getPrice(originalData.value));
      }
      displayedSeries.push({
        time: originalData.time,
        value: newValue,
      });
    }

    if (seriesData.length) {
      if (currency === "USD") {
        setInitialIntervalPrice(
          parseFloat(getPrice(seriesData[0]["value"]).substring(1))
        );
        setCurrentPrice(
          parseFloat(
            getPrice(seriesData[seriesData.length - 1]["value"]).substring(1)
          )
        );
      } else {
        setInitialIntervalPrice(parseFloat(getPrice(seriesData[0]["value"])));
        setCurrentPrice(
          parseFloat(getPrice(seriesData[seriesData.length - 1]["value"]))
        );
      }
    }

    areaSeriesRef.current.setData(displayedSeries);

    return () => {
      if (chartRef.current && areaSeriesRef.current) {
        try {
          chartRef.current.removeSeries(areaSeriesRef.current);
        } catch (error) {
          console.error("Error removing series:", error);
        }
      }
    };
  }, [
    loading,
    chartRef.current,
    seriesesData,
    activeInterval,
    category,
    getPrice,
    isWhitelisted,
    isDefaultGraph,
  ]);

  const handleIntervalChange = (interval) => setActiveInterval(interval);

  return (
    <div>
      <div className="chart-title">
        Price of {category} Collection ({currency})
      </div>
      <div className="legend-text">
        {legendInfo.priceFormatted}
        {` `}
        <span style={{ color: legendInfo.isPositive ? "#26AD75" : "#F0616D" }}>
          ({legendInfo.percentChange})
        </span>
      </div>
      <div className="controls-container">
        <div className="switcher">
          {intervals.map((item) => (
            <button
              key={item}
              className={`switcher-item ${
                item === activeInterval ? "switcher-active-item" : ""
              }`}
              onClick={() => handleIntervalChange(item)}
            >
              {item === "30d" ? "All" : item}
            </button>
          ))}
        </div>
        <CustomDropdown category={category} setCategory={setCategory} />
      </div>
      <div>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginBottom: -300,
              height: "300px",
              minHeight: "300px",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {!loading && !isWhitelisted && !isDefaultGraph && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginBottom: -300,
              height: "300px",
              minHeight: "300px",
            }}
          >
            <PaymentModal />
          </div>
        )}
        <div
          style={{
            width: "100%",
            height: "300px",
            minHeight: "300px",
            visibility:
              loading || (!isWhitelisted && !isDefaultGraph)
                ? "hidden"
                : "visible",
            touchAction: "none",
          }}
          ref={chartContainerRef}
        ></div>
      </div>
    </div>
  );
};

export default PriceChart;
