import React, { useEffect, useContext, useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import SearchIcon from "@mui/icons-material/Search";
import baseUrl from "./baseUrl";
import { UserContext } from "./userContext";

import logo from "./rabbit.png";
import {
  useWeb3Modal,
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { AppBar, Button } from "@mui/material";
import SearchBar from "./SearchBar";
import CurrencyToggle from "./CurrencyToggle";

const Nav = ({ onCurrencyToggle, currency }) => {
  const handleCurrencyChange = (e, newCurrency) => {
    if (newCurrency !== null) {
      onCurrencyToggle(newCurrency);
    }
  };
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  // const [ addresses, setAddresses ] = useState([]);
  const { addresses, setSelectedAddress, selectedAddress } =
    useContext(UserContext);

  const fetchAllAddresses = async () => {
    try {
      const url = `${baseUrl}/fetchAllUsers`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.log("Error fetching all addresses:", error);
    }
  };

  useEffect(() => {
    // console.log(address, chainId, isConnected, walletProvider);
    // fetchAllAddresses().then((data) => {
    // console.log("data.combinedUsers: ", data.combinedUsers);
    // setAddresses(data.combinedUsers);
    // });
    // console.log("selectedAddress: ", selectedAddress);
  }, [address, chainId, isConnected, walletProvider, selectedAddress]);

  const shortenAddress = (address) => {
    if (!address) return "";
    return `${address.substring(0, 4)}...${address.substring(
      address.length - 4
    )}`;
  };

  const [showFullSearchBar, setShowFullSearchBar] = useState(false);

  return (
    <AppBar
      style={{
        backgroundColor: "#1e1e1e",
        display: "flex",
        alignItems: "center",
      }}
    >
      <nav style={styles.nav}>
        {showFullSearchBar ? (
          <>
            <SearchBar />
            <Button
              style={{ color: "lightgray" }}
              onClick={() => {
                setShowFullSearchBar(false);
              }}
            >
              Cancel
            </Button>
            <CurrencyToggle />
          </>
        ) : (
          <>
            <h1 style={styles.logo}>
              <img
                src={logo}
                alt="logo"
                style={{ width: "50px", height: "50px" }}
              />
              {window.innerWidth > 600 && "SofamonPro"}
            </h1>
            <div style={styles.navItems}>
              {window.innerWidth < 600 ? (
                <SearchIcon
                  fontSize="large"
                  onClick={() => {
                    setShowFullSearchBar(true);
                  }}
                />
              ) : (
                <SearchBar />
              )}
              <CurrencyToggle />
              <button onClick={() => open()} style={styles.connectWalletBtn}>
                {isConnected ? "Wallet Connected" : "Connect Wallet"}
                <br />
                <small>{shortenAddress(address)}</small>
              </button>
            </div>
          </>
        )}
      </nav>
    </AppBar>
  );
};

// Define your nav styles here
const styles = {
  nav: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    backgroundColor: "#1e1e1e",
    color: "white",
    width: "95%",
    maxWidth: "1600px",
  },
  logo: {
    margin: "0",
  },
  navItems: {
    display: "flex",
    alignItems: "center",
    gap: "25px",
  },
  connectWalletBtn: {
    backgroundColor: "rgba(41,97,255, 1)", // Style as needed
    color: "white",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
    borderRadius: "5px",
    marginRight: "10px",
  },
};

export default Nav;
