import React, { useContext } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AppContext from "./AppContext";
import { useWeb3ModalState } from "@web3modal/ethers/react";

const CurrencyToggle = () => {
  const handleCurrencyChange = (e, newCurrency) => {
    if (newCurrency !== null) {
      setCurrency(newCurrency);
    }
  };
  const { open } = useWeb3ModalState();

  const { currency, setCurrency } = useContext(AppContext);

  return (
    <ToggleButtonGroup
      value={currency}
      exclusive
      onChange={handleCurrencyChange}
      style={
        window.innerWidth < 600
          ? {
              ...styles.toggleGroup,
              position: "fixed",
              bottom: 30,
              right: 30,
              visibility: open ? "hidden" : "visible",
            }
          : styles.toggleGroup
      }
    >
      <ToggleButton
        value="USD"
        sx={{
          // Common styles for both buttons

          "&.Mui-selected": {
            backgroundColor: "rgba(41,97,255, 1)", // Assuming #2196f3 for illustration
            color: "#fff",
            "&:hover": {
              backgroundColor: "#1976d2", // Darker shade of blue for selected hover
            },
          },
          "&:not(.Mui-selected)": {
            color: "#000",
            "&:hover": {
              backgroundColor: "#e3f2fd", // Soft blue for non-selected hover
            },
          },
        }}
        style={styles.toggleButton}
      >
        USD
      </ToggleButton>
      <ToggleButton
        value="ETH"
        sx={{
          // Common styles for both buttons

          "&.Mui-selected": {
            backgroundColor: "rgba(41,97,255, 1)", // Assuming #2196f3 for illustration
            color: "#fff",
            "&:hover": {
              backgroundColor: "#1976d2", // Darker shade of blue for selected hover
            },
          },
          "&:not(.Mui-selected)": {
            color: "#000",
            "&:hover": {
              backgroundColor: "#e3f2fd", // Soft blue for non-selected hover
            },
          },
        }}
        style={styles.toggleButton}
      >
        ETH
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

// Define your nav styles here
const styles = {
  toggleGroup: {
    backgroundColor: "gray",
    color: "white",
    border: "none",
    borderRadius: "5px",
  },
  toggleButton: {
    color: "white",
    border: "1px solid #555", // Style as needed
  },
};

export default CurrencyToggle;
