import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import UserActivity from "./userActivity";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { CircularProgress } from "@mui/material";
import AppContext from "./AppContext";
import baseUrl from "./baseUrl";
import { UserContext } from "./userContext";
// Define your table styles as a JavaScript object
const styles = {
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#1e1e1e",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  table: {
    width: "100%", // Full width of the container
    borderCollapse: "collapse",
    overflowX: "auto",
  },
  cell: {
    padding: "8px",
    textAlign: "left",
    borderBottom: "1px solid #333",
  },
  buy: {
    color: "#2dd28e",
  },
  sell: {
    color: "#f03d0f",
  },
};

const SofamonInfo = () => {
  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = useState(false);

  const { getPrice } = React.useContext(AppContext);

  const { addressesAndProfiles } = React.useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await axios.get(
        `${baseUrl}/fetchGlobalActivities?page=${page}`
      );
      setData(response.data);
      setLoading(false);
    };
    fetchData();
  }, [page]);

  const tableContainerRef = useRef();
  const [tableContainerWidth, setTableContainerWidth] = useState(0);
  const [tableContainerHeight, setTableContainerHeight] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      const currentWidth = tableContainerRef.current
        ? tableContainerRef.current.offsetWidth
        : 0;
      setTableContainerWidth(currentWidth);
      const currentHeight = tableContainerRef.current
        ? tableContainerRef.current.offsetHeight
        : 0;
      setTableContainerHeight(currentHeight);
    };
    window.addEventListener("resize", updateWidth);
    updateWidth();
  }, []);

  return (
    <div style={{ ...styles.tableContainer, minHeight: tableContainerHeight }}>
      <h2>Sofamon Global Activity:</h2>
      <div ref={tableContainerRef}>
        <table
          style={{
            ...styles.table,
            display: tableContainerWidth < 400 ? "block" : "",
          }}
        >
          <tbody
            style={{
              width: "100%",
            }}
          >
            <tr>
              <th style={{ ...styles.cell }}>{"Date"}</th>
              <th style={{ ...styles.cell }}>{"Action"}</th>
              <th style={{ ...styles.cell }}>{"Qty"}</th>
              <th style={{ ...styles.cell }}>{"Item"}</th>
              <th style={{ ...styles.cell }}>{"Amount"}</th>
              <th style={{ ...styles.cell }}>{"User"}</th>
            </tr>
            {loading && (
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    padding: 100,
                    borderBottom: "none",
                  }}
                  colSpan="7"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <CircularProgress />
                  </div>
                </td>
              </tr>
            )}
            {data.activities &&
              !loading &&
              data.activities.map((activity, index) => (
                <tr key={index}>
                  <td style={styles.cell}>{activity.timestamp}</td>
                  <th
                    style={{
                      ...styles.cell,
                      ...(activity.action === "buy" ? styles.buy : styles.sell),
                    }}
                  >
                    {activity.action}
                  </th>
                  <td style={styles.cell}>{activity.quantity}</td>
                  <td style={styles.cell}>{activity.wearable}</td>
                  <td style={styles.cell}>{`${getPrice(activity.price)}`}</td>
                  <td style={styles.cell}>{addressesAndProfiles?.[activity.trader]?.["username"] || activity.trader.substring(0, 6)}</td>
                  <td style={styles.cell}>{activity.new_price}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div
        style={{
          marginTop: 15,
          marginBottom: 15,
          flexDirection: "row",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Stack spacing={2}>
          <Pagination
            style={{ width: "100%" }}
            count={Math.ceil(data.count / 10)}
            siblingCount={tableContainerWidth < 400 ? 0 : 1}
            variant="outlined"
            shape="rounded"
            size={tableContainerWidth < 400 ? "small" : "medium"}
            sx={{
              "& .MuiPaginationItem-page.Mui-selected": {
                backgroundColor: "#2dd28e",
              },
              "& .MuiPaginationItem-page.Mui-selected: hover": {
                backgroundColor: "#2dd28e",
              },
              "& .MuiPaginationItem-ellipsis": {
                color: "#ffffff",
              },
              "& .MuiPaginationItem-page:hover": {
                backgroundColor: "#2dd28e",
              },
              "& .MuiPaginationItem-outlined": {
                color: "#ffffff",
                borderColor: "#ffffff", // Change this to your desired border color
              },
            }}
            onChange={(event, value) => setPage(value)}
          />
        </Stack>
      </div>
    </div>
  );
};

export default SofamonInfo;
