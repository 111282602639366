import React, { useContext, useState } from "react";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { BrowserProvider, Contract, parseEther } from "ethers";
import WhitelistABI from "./artifacts/contracts/Whitelist.sol/Whitelist.json";
import { Button, CircularProgress } from "@mui/material";
import { WHITELIST_CONTRACT_ADDRESS } from "./constants";
import AppContext from "./AppContext";
import axios from "axios";
import baseUrl from "./baseUrl";
import * as amplitude from "@amplitude/analytics-browser";
amplitude.init("456751549960bd00fc01601c0cd9ef0a");

function PaymentButton({ handleNoWallet }) {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const { setIsWhitelisted } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const checkIsWhitelisted = async (WhitelistContract) => {
    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    try {
      let isWhitelisted = false;
      setLoading(true);
      while (!isWhitelisted) {
        await sleep(2000);
        let response = await axios.get(
          `${baseUrl}/isWhitelisted?wallet=${address}`
        );
        isWhitelisted = response.data.isWhitelisted;
        let contractIsWhitelisted = await WhitelistContract.isWhitelisted(
          address
        );
        console.log(`Is the user whitelisted? ${isWhitelisted}`);
        isWhitelisted = isWhitelisted || contractIsWhitelisted;
      }
      let response = await axios.post(`${baseUrl}/addWallet`, {
        wallet: address,
      });
      console.log(response.data);
      response = await axios.post(`${baseUrl}/login`, {
        wallet: address,
      });
      const token = response.data;
      localStorage.setItem("authToken", token);
      amplitude.track("Login");
      setLoading(false);
      setIsWhitelisted(true);
    } catch (error) {
      let errorMessage = error.reason || error.message;
      if (errorMessage.includes("revert")) {
        const revertReasonMatch = errorMessage.match(/revert (.*)/);
        if (revertReasonMatch && revertReasonMatch[1]) {
          errorMessage = revertReasonMatch[1];
        }
      }
      alert(errorMessage);
    }
  };

  const addToWhitelist = async () => {
    if (!isConnected) {
      alert(
        "Please connect a payment wallet first. It doesn't need to be your Sofamon wallet. "
      );
      handleNoWallet();
      return;
    }
    try {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();

      const WhitelistContract = new Contract(
        WHITELIST_CONTRACT_ADDRESS[chainId],
        WhitelistABI.abi,
        signer
      );

      const price = await WhitelistContract.whitelistPrice();

      await WhitelistContract.addToWhitelist({
        value: parseEther(`${Number(price) / Number(1e18)}`),
      });
      amplitude.track("Pay");
      checkIsWhitelisted(WhitelistContract);
    } catch (error) {
      let errorMessage = error.reason || error.message;
      if (errorMessage.includes("revert")) {
        const revertReasonMatch = errorMessage.match(/revert (.*)/);
        if (revertReasonMatch && revertReasonMatch[1]) {
          errorMessage = revertReasonMatch[1];
        }
      }
      alert(
        "**Please make sure your wallet is on the Base etheruem network and have sufficient funds... \n\nFor more issues, contact +19495298312"
      );
    }
  };
  return (
    <Button
      variant="contained"
      sx={{
        marginTop: 2,
        color: "white",
        backgroundColor: "#BB86FC",
      }}
      disabled={loading}
      onClick={addToWhitelist}
    >
      {!loading ? (
        "Purchase Membership"
      ) : (
        <>
          <CircularProgress size={16} />
          &nbsp; Waiting for Confirmation
        </>
      )}
    </Button>
  );
}

export default PaymentButton;
