const WHITELIST_CONTRACT_ADDRESS = {
  8453: "0x92fd9809d41d0CFB5A32aA7EA4b1A31bcBd974DD",
  84532: "0x8De5E7f509c0bc4beF243a54EF0195dB802E08A3",
};

const NETWORKS = {
  base: {
    chainId: 8453,
    name: "Base Mainnet",
    currency: "ETH",
    explorerUrl: "https://basescan.org",
    rpcUrl:
      "https://base-mainnet.g.alchemy.com/v2/UA0ly4dOTQwlziMkvQE_DHx1cGs015II",
  },
  sepolia: {
    chainId: 84532,
    name: "Base Sepolia",
    currency: "ETH",
    explorerUrl: "https://sepolia-explorer.base.org",
    rpcUrl: "https://sepolia.base.org",
  },
};

export { WHITELIST_CONTRACT_ADDRESS, NETWORKS };
