import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from './App';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import { NETWORKS } from "./constants";

// 1. Get projectId
const projectId = "26c3fb907cb6c977e2a8258b17f5e783";

// 3. Create a metadata object
const metadata = {
  name: "SofamonPro",
  description: "SofamonPro",
  url: "https://www.sofamonpro.com",
  // icons: ['https://avatars.mywebsite.com/']
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: false, // true by default
});

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [NETWORKS["base"]],
  // chains: [NETWORKS["base"], NETWORKS["sepolia"]],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
