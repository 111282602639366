import React, { createContext, useState, useEffect } from "react";
import baseUrl from "./baseUrl";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // const [addresses, setAddresses] = useState([]);
  const [addressesAndProfiles, setAddressesAndProfiles] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [displayName, setDisplayName] = useState(null);

  const { address } = useWeb3ModalAccount();

  // Set default selected address to the user's payment address
  useEffect(() => {
    setSelectedAddress(address);
  }, [address]);

  // Set selected profile when selected address changes
  useEffect(() => {
    if (addressesAndProfiles) {
      const profile = addressesAndProfiles[selectedAddress];
      setSelectedProfile(profile);
    }
  }, [selectedAddress, addressesAndProfiles]);

  useEffect(() => {
    if (selectedProfile && selectedProfile["username"]) {
      setDisplayName(selectedProfile["username"]);
    } else if (selectedAddress) {
      setDisplayName(selectedAddress.substring(0, 6));
    }
  }, [selectedProfile, selectedAddress]);

  // const fetchAllAddresses = async () => {
  //   try {
  //     const url = `${baseUrl}/fetchAllUsers`;
  //     const response = await fetch(url, {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     const data = await response.json();
  //     return data;
  //   } catch (error) {
  //     console.log("Error fetching all addresses:", error);
  //   }
  // };

  const fetchAllAddressesAndProfiles = async () => {
    try {
      const url = `${baseUrl}/fetchAllUsersAndProfiles`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.log("Error fetching all addresses:", error);
    }
  };

  useEffect(() => {
    // fetchAllAddresses().then((data) => {
    //   setAddresses(data.combinedUsers);
    // });
    fetchAllAddressesAndProfiles().then((data) => {
      if (data.addressesToProfiles) {
        setAddressesAndProfiles(data.addressesToProfiles);
      }
    });
  }, []);

  const contextData = {
    addressesAndProfiles,
    selectedAddress,
    setSelectedAddress,
    selectedProfile,
    displayName,
  };

  return (
    <UserContext.Provider value={contextData}>{children}</UserContext.Provider>
  );
};
